
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "HamburgerOpenButton3537389287",
      {"classes":{"root":"HamburgerOpenButton3537389287__root","nav":"HamburgerOpenButton3537389287__nav"},"vars":{},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  