('use-client');

import React from 'react';
import HamburgerCloseButtonCommon from '../../HamburgerCloseButton';
import { classes, st } from './HamburgerCloseButton.component.st.css';
import { classes as stylableButtonClasses } from '../../../../../StylableButton/viewer/StylableButton.component.st.css';
import type {
  IStylableButtonImperativeActions,
  IStylableButtonProps,
} from '../../../../../StylableButton/StylableButton.types';

const DefaultSkin: React.ForwardRefRenderFunction<
  IStylableButtonImperativeActions,
  IStylableButtonProps
> = props => {
  const extendedProps = {
    ...props,
    classes: {
      ...classes,
      hamburgerCloseButtonRoot: st(classes.root),
      stylableButtonRoot: st(stylableButtonClasses.root),
    },
  };
  return <HamburgerCloseButtonCommon {...extendedProps} />;
};

export default React.forwardRef(DefaultSkin);
