import type { PropsWithChildren } from 'react';
import React from 'react';
import { useMenuItemContext } from '../../../../../common/menu/MenuItemContext';
import classes from '../MenuItem/style/MenuItem.scss';
import { MenuParts, INITIAL_DROPDOWN_ANIMATION_STATE } from '../../constants';
import type { MenuOrientationType } from '../../../Menu.types';

type DropdownWrapperProps = PropsWithChildren<{
  id: string;
  menuOrientation?: MenuOrientationType;
  childrenWrapperClassName?: string;
  dataAttributes?: any;
}>;

export const DropdownWrapper: React.FC<DropdownWrapperProps> = ({
  id,
  childrenWrapperClassName,
  dataAttributes,
  children,
  menuOrientation = 'horizontal',
}) => {
  const {
    dropdownAnimationState: animationState = INITIAL_DROPDOWN_ANIMATION_STATE,
    item,
  } = useMenuItemContext();

  return (
    <div
      id={id}
      {...dataAttributes}
      className={
        menuOrientation === 'horizontal'
          ? classes.horizontalDropdown
          : classes.verticalDropdown
      }
      role="group"
      aria-label={item.label}
      data-part={MenuParts.DropdownContainer}
    >
      <div
        className={childrenWrapperClassName}
        data-animation-name={animationState.name}
        data-animation-state={animationState.phase}
      >
        {children}
      </div>
    </div>
  );
};
