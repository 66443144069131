('use-client');

import React from 'react';
import HamburgerOpenButtonCommon from '../../HamburgerOpenButton';
import type {
  IStylableButtonImperativeActions,
  IStylableButtonProps,
} from '../../../../../StylableButton/StylableButton.types';
import styles from './HamburgerOpenButton.scss';

const HamburgerOpenButtonSkin: React.ForwardRefRenderFunction<
  IStylableButtonImperativeActions,
  IStylableButtonProps
> = props => {
  const extendedProps = {
    ...props,
    classes: {
      ...styles,
      hamburgerOpenButtonRoot: styles.root,
    },
  };
  return <HamburgerOpenButtonCommon {...extendedProps} />;
};

export default React.forwardRef(HamburgerOpenButtonSkin);
