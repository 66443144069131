('use-client');

import React from 'react';
import HamburgerCloseButtonCommon from '../../HamburgerCloseButton';
import type {
  IStylableButtonImperativeActions,
  IStylableButtonProps,
} from '../../../../../StylableButton/StylableButton.types';
import styles from './HamburgerCloseButton.scss';

const HamburgerCloseButtonSkin: React.ForwardRefRenderFunction<
  IStylableButtonImperativeActions,
  IStylableButtonProps
> = props => {
  const extendedProps = {
    ...props,
    classes: {
      ...styles,
      hamburgerCloseButtonRoot: styles.root,
    },
  };
  return <HamburgerCloseButtonCommon {...extendedProps} />;
};

export default React.forwardRef(HamburgerCloseButtonSkin);
